import { ActionError, AgnosticProduct, AgnosticProductSearchParams } from 'shared/types'
import { computed, ref } from 'vue'
const logger = Logger.getLogger({ scope: 'agnostic', method: 'useProduct' })

export function useProduct(id: string) {
  const ssrKey = 'useProduct'
  const products = ref<readonly Readonly<AgnosticProduct>[]>([])
  const loading = ref<boolean>(false)
  const error = ref<{ search?: ActionError }>({})
  const loadingAvailability = ref(false)
  const api = useEcommerce()

  const search = async (searchParams: AgnosticProductSearchParams) => {
    logger.debug(`useProduct/${id}/search`, searchParams)

    try {
      loading.value = true
      const result = await api.getProduct(searchParams)
      if (result.ok) {
        products.value = result.payload
        error.value.search = undefined
      } else {
        error.value.search = result.error
      }
    } catch (err) {
      error.value.search = {
        __general__: String(err)
      }
      logger.error(`${ssrKey}/${id}/search`, err)
    } finally {
      loading.value = false
    }
  }

  return {
    async syncAvailability() {
      if (process.client) {
        const product = products.value[0]
        const availabilityExpires = product?.availabilityExpires

        if (
          product &&
          (product.type === 'VARIATION_MASTER' || product.type === 'STANDARD' || product.type === 'VARIATION_GROUP') &&
          availabilityExpires &&
          availabilityExpires < Date.now()
        ) {
          loadingAvailability.value = true
          await api
            .getProductAvailability({ id: product.id })
            .then((availabilitesResult) => {
              if (availabilitesResult.ok) {
                const newProduct = {
                  ...product,
                  variations: product.variations.map((variant, idx) => {
                    return {
                      ...variant,
                      availability: availabilitesResult.payload[idx] ?? variant.availability
                    }
                  })
                }
                products.value = [newProduct]
              }
            })
            .finally(() => {
              loadingAvailability.value = false
            })
        }
      }
    },
    search,
    products: computed(() => products.value),
    loading: computed(() => loading.value),
    loadingAvailability: computed(() => loadingAvailability.value),
    error: computed(() => error.value)
  }
}
