import { AgnosticProduct } from 'shared/types'
import { ACCEPTED_ALL_KEY } from 'shared/constants'

const storageKey = 'asf-last-visited'

export function useLastVisited() {
  const { $preferences } = useNuxtApp()
  const { lastVisitedEnabled: isEnabled, cookiesNoticeEnabled } = $preferences ?? {}
  const products = ref<readonly AgnosticProduct[]>([])
  const { search, products: lastProducts, loading } = useProduct('lastVisited')
  const cookieAllAccepted = getLocalStorageItem(ACCEPTED_ALL_KEY)

  if (isEnabled && process.client) {
    let lastVisited: string[] = []
    try {
      lastVisited = tryParse(getLocalStorageItem(storageKey), [] as string[])
    } catch (e) {
      Logger.getLogger({ scope: 'agnostic', method: 'useLastVisited' }).error(e)
    }

    if (lastVisited.length) {
      const ricb = window.requestIdleCallback || nextTick
      // use next tick to avoid init call in onMountet which cause to resolve with null
      ricb(() => {
        search({
          ids: lastVisited.slice(0, 12)
        }).then(() => {
          products.value = lastProducts.value
        })
      })
    }
  }

  return {
    setVisited(product: AgnosticProduct) {
      if (!(isEnabled && process.client && (cookieAllAccepted || !cookiesNoticeEnabled))) {
        return
      }

      const productNotIn = (p: AgnosticProduct) => p.id !== product.id
      const firstEverVisit = !products.value.find((p) => p.id === product.id)

      products.value = firstEverVisit
        ? [...products.value.filter(productNotIn)]
        : [product, ...products.value.filter(productNotIn)]

      try {
        const lastVisitedPids = [
          product.id,
          ...tryParse(getLocalStorageItem(storageKey), [] as string[]).filter((id) => product.id !== id)
        ]

        setLocalStorageItem(storageKey, JSON.stringify(lastVisitedPids))
      } catch (e) {
        Logger.getLogger({ scope: 'agnostic', method: 'setVisited' }).error(e)
      }
    },
    products: computed(() => products.value),
    loading: computed(() => loading.value)
  }
}
