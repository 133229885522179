<template>
  <div
    v-e2e="'ta-product-tile'"
    class="asf-product-tile"
    :class="{ 'is-sold-out': !variationModel.inStock }"
    :aria-label="`${$t('accessibility.group.plp.productTile')}`"
    role="group"
    tabindex="-1"
  >
    <AsfLink :link="link">
      <div class="asf-product-tile__top">
        <div
          ref="imageWrapper"
          v-e2e="'image-product-tile'"
          class="asf-product-tile__image-wrapper"
          :class="{
            'is-alternate-active': isAlternateImageActive,
            'm-image-alternate': image.isAlternate
          }"
          @scroll="handleImageScroll"
        >
          <AsfImage
            :title="image.main.title"
            :alt="image.main.alt"
            :src="image.main.src"
            :width="image.main.width"
            :height="image.main.height"
            :adaptive="image.main.adaptive"
            :lazy="lazy"
            :fetch-priority="fetchPriority"
            class="asf-product-tile__image"
          />
          <AsfImage
            v-if="image.isAlternate"
            :src="image.alternate.src"
            :alt="image.alternate.src"
            :adaptive="image.alternate.adaptive"
            :lazy="lazy"
            :fetch-priority="fetchPriority"
            class="asf-product-tile__image is-alternate"
          />
        </div>
      </div>
      <div v-if="!variationModel.inStock" class="asf-product-tile__sold-out">
        {{ $t('product.soldOut') }}
      </div>
      <AsfHeading v-if="product.name" tag="h2" class="asf-product-tile__heading">
        {{ product.name }}
      </AsfHeading>
    </AsfLink>

    <AsfPrice :prices="variationModel.prices" />

    <AsfPromotion
      v-for="promotion in variationModel.promotions"
      :key="promotion.id"
      :discount-msg="promotion.discountMsg"
    />

    <div v-if="colorSwatches.shown.length" class="asf-product-tile__variations">
      <AsfVariationSwatch
        v-for="variant in colorSwatches.shown"
        :key="variant.value"
        v-bind="variant"
        :selected="variant.selected"
        :no-swatches-highlighter="noSwatchesHighlighter"
        :product-id="product.id"
      />
      <AsfLink
        v-if="colorSwatches.hidden.length"
        class="asf-product-tile__variations-all"
        :title="$t('global.showAll')"
        :aria-label="$t('global.showAll')"
        :link="link"
      >
        +{{ colorSwatches.hidden.length }}
      </AsfLink>
    </div>

    <slot name="ratings" :ratings-product="product" :ratings-link="link" />
    <slot name="wishlist-icon" :wishlist-product="variationModel.selectedVariant || product" />
  </div>
</template>
<script lang="ts">
import throttle from 'lodash.throttle'
import { AgnosticBaseFacetAttrs } from 'shared/types'
import { AsfProductTileProps, AsfVariationSwatchProps } from '@ui/types'
import type { AsfLink } from '#components'

export default defineComponent({
  name: 'AsfProductTile',
  props: {
    /** Product data */
    product: { type: Object as PropType<AsfProductTileProps['product']>, required: true },
    /** Count of swatches to show */
    visibleSwatchCount: {
      type: Number as PropType<AsfProductTileProps['visibleSwatchCount']>,
      default: MAX_MD_VARIATION_SWATCH_COUNT
    },
    /** Highlighting active color swatch */
    noSwatchesHighlighter: { type: Boolean as PropType<AsfProductTileProps['noSwatchesHighlighter']>, default: false },
    lazy: { type: Boolean as PropType<AsfProductTileProps['lazy']>, default: true },
    fetchPriority: { type: String as PropType<AsfProductTileProps['fetchPriority']>, default: 'high' }
  },
  setup(props: AsfProductTileProps) {
    const product = computed(() => props.product)
    const { getProductURL } = useURL()
    const { variationModel } = useVariationModel(product)

    const colorSwatches = computed(() => {
      if (!variationModel.value.variationAttributes.color || props.product.type !== 'VARIATION_MASTER') {
        return { shown: [], hidden: [] }
      }

      const allColorSwatches =
        variationModel.value.variationAttributes.color.map((ca, idx): AsfVariationSwatchProps => {
          return {
            type: 'color',
            name: ca.label || '',
            value: ca.value,
            selected: idx === 0,
            link: getProductURL(product.value, [{ name: AgnosticBaseFacetAttrs.color, value: ca.value }])
          }
        }) || []

      const shown = allColorSwatches.slice(0, props.visibleSwatchCount)
      const hidden = allColorSwatches.slice(shown.length)

      return { shown, hidden }
    })

    // TODO: @i.khartov, please refactor this logic.
    const isAlternateImageActive = ref(false)

    const image = computed(() => {
      const alternateImage = {
        src: '',
        alt: product.value.name,
        adaptive: true
      }
      return {
        alternate: alternateImage,
        isAlternate: Boolean(alternateImage.src),
        main: {
          src: '',
          alt: product.value.name,
          title: product.value.name,
          width: 276,
          height: 368,
          adaptive: true,
          ...(product.value.images.at(0) || {})
        }
      }
    })

    const imageWrapper = ref<InstanceType<typeof AsfLink> | null>(null)

    const handleImageScroll = () => {
      if (!image.value.isAlternate || !imageWrapper.value) return
      const element = imageWrapper.value.$el

      if (element) {
        isAlternateImageActive.value = element.scrollLeft > element.clientWidth / 2
      }
    }

    return {
      image,
      variationModel,
      imageWrapper,
      colorSwatches,
      isAlternateImageActive,
      link: computed(() => getProductURL(product.value)),
      handleImageScroll: throttle(handleImageScroll, 200)
    }
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/ProductTile/ProductTile.css';
</style>
