<template>
  <AsfRating
    v-if="ratingEnabled"
    :id="product.id"
    :link="link"
    :count="count"
    :score="score"
    :is-link-message="isLinkMessage"
    :is-anchor="isAnchor"
  />
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { AgnosticProduct } from 'shared/types'
import { AsfRatingProps } from '@ui/types'

type ProductRatingsProps = {
  product: AgnosticProduct
  link: AsfRatingProps['link']
  isAnchor?: AsfRatingProps['isAnchor']
  isLinkMessage?: AsfRatingProps['isLinkMessage']
}

export default defineComponent({
  name: 'ProductRatings',
  props: {
    product: { type: Object as PropType<ProductRatingsProps['product']>, required: true },
    link: { type: [String, Object] as PropType<ProductRatingsProps['link']>, default: '' },
    isLinkMessage: { type: Boolean as PropType<ProductRatingsProps['isLinkMessage']>, default: false },
    isAnchor: { type: Boolean as PropType<ProductRatingsProps['isAnchor']>, default: false }
  },
  setup(props: ProductRatingsProps) {
    const { $preferences } = useNuxtApp()

    const score = computed(() => parseFloat(props.product.properties?.reviewAverageRating?.value ?? '') || 0)
    const count = computed(() => parseFloat(props.product.properties?.reviewCount?.value ?? '') || 0)

    return { score, count, ratingEnabled: $preferences.ratingEnabled }
  }
})
</script>
