<template>
  <ClientOnly v-if="$preferences?.lastVisitedEnabled">
    <LazyHydrationWrapper when-idle>
      <ProductSlider
        :products="products"
        :loading="loading"
        :title="$t('product.recently.viewed')"
        @product:clicked="handleProductClick"
      />
    </LazyHydrationWrapper>
  </ClientOnly>
</template>

<script lang="ts">
import { defineComponent, PropType, watchEffect } from 'vue'
import { AgnosticProduct } from 'shared/types'

export default defineComponent({
  name: 'AppLastVisitedProducts',
  props: {
    product: { type: Object as PropType<AgnosticProduct>, default: null }
  },
  setup(props) {
    const { products, loading, setVisited } = useLastVisited()
    // const { reportProductImpressions, reportProductClick } = useTagManager()

    const getProducts = () => {
      if (props.product?.id) {
        return setVisited(props.product)
      }
    }

    const loadProducts = async () => {
      await getProducts()
      // reportProductImpressions(products.value, 'Products Carousel')
    }

    watchEffect(loadProducts)

    const handleProductClick = (/* payload: { product: AgnosticProduct; position: number } */) => {
      // reportProductClick(payload.product, 'Products Carousel', { position: payload.position })
    }

    return { products, loading, handleProductClick }
  }
})
</script>
