<template>
  <WishlistIcon :selected="isInWishlist(product)" :loading="loading" @click="toggleWishlist" />
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { ActionError, AgnosticProduct, AgnosticVariant } from 'shared/types'

export default defineComponent({
  name: 'ProductWishlistIcon',
  props: {
    product: { type: Object as PropType<AgnosticProduct | AgnosticVariant>, required: true }
  },
  setup(props) {
    const { toggleProduct, loading, isInWishlist, error } = useWishlist()

    const { t } = useI18n()
    const { send: notify } = useUiNotification()

    const wishlistError = computed<ActionError | undefined>(() => {
      return error.value.add || error.value.remove
    })

    const toggleWishlist = async () => {
      const isIn = isInWishlist(props.product)
      await toggleProduct(props.product)

      if (!wishlistError.value) {
        notify(`toggleWishlist-${isIn ? 'removed' : 'added'}`, {
          message: isIn ? t('notifications.wishlistRemoved') : t('notifications.wishlistAdded')
        })
      } else {
        notify('toggleWishlist', {
          type: 'error',
          message: t(wishlistError.value.__general__)
        })
      }
    }

    return { loading, isInWishlist, toggleWishlist }
  }
})
</script>
