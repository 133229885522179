<template>
  <AsfLink
    class="asf-rating"
    :link="link"
    :is-anchor="isAnchor"
    :title="$t('rating.label', { count: finalScore })"
    :aria-label="$t('rating.label', { count: finalScore })"
  >
    <AsfIcon
      v-for="n in 5"
      :id="`${id}-${n}`"
      :key="n"
      name="star"
      size="4"
      class="asf-rating__icon"
      :class="{
        'is-star-filled': n <= finalScore,
        'is-star-empty': n > Math.ceil(finalScore),
        'is-star-half': !(n <= finalScore || n > Math.ceil(finalScore))
      }"
    />
    <span class="asf-rating-number">{{ isLinkMessage ? $t('product.reviews', { count }) : `(${count})` }}</span>
  </AsfLink>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { AsfRatingProps } from '@ui/types'

export default defineComponent({
  name: 'AsfRating',
  props: {
    id: { type: String as PropType<AsfRatingProps['id']>, required: true },
    /** Used for scroll to an anchor on the same page */
    isAnchor: { type: Boolean as PropType<AsfRatingProps['isAnchor']>, default: false },
    /** Used to wrap reviews counter using localized message */
    isLinkMessage: { type: Boolean as PropType<AsfRatingProps['isLinkMessage']>, default: false },
    /** Used to navigate to another page */
    link: { type: String as PropType<AsfRatingProps['link']>, required: true },
    /** Average review score */
    score: { type: Number as PropType<AsfRatingProps['score']>, default: 0 },
    /** Reviews count */
    count: { type: Number as PropType<AsfRatingProps['count']>, default: 0 }
  },

  setup(props: AsfRatingProps) {
    const finalScore = computed(() => {
      if (!props.score || props.score < 0) {
        return 0
      } else if (props.score > 5) {
        return 5
      } else {
        return props.score
      }
    })

    return { finalScore }
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/Rating/Rating.css';
</style>
